import React from "react"
import ReadMoreBtn from "./read-more-btn";
import images from "./images";
import {graphql, useStaticQuery} from "gatsby";
import SectionMainHeading from "./section-main-heading";

export default function StaticBlogBanner(allBlogs) {

//3eca6af6-ed52-5969-b3c0-872698cebe87 => Food Supplements

    function fixLength(str, l){
        if(str === '' || str === undefined || str === null) return '';
        let a = str.substring(0,l).split(' ');
        a.pop();
        return a.join(' ')+'...'
    }

    let q = useStaticQuery(graphql`
  query {
  allWordpressCategory(filter: {parent_element: {id: {eq: "3eca6af6-ed52-5969-b3c0-872698cebe87"}}}) { 
    edges {
      node {
        name
      }
    }
  }
  }`)

    let cats = ['Food Supplements'];

    cats = cats.concat(q.allWordpressCategory.edges.map(e => e.node.name));

    let blogs = allBlogs.data.map(e => {
        let f = e.filter(function(e2) {
            if(e2.node.categories !== undefined && e2.node.categories !== null){
                if(e2.node.categories.filter(e3 => cats.includes(e3.name)).length){
                    return e2.node
                }
            }
        })
        if(f.length > 0) return f[0].node

        })


    let indv = '';
    let ind = 0;
    blogs.forEach(function(a, i){

        if(a !== undefined){
            if(indv !== ''){
                if(new Date(a.date) > new Date(indv)){
                    indv = a.date;
                    ind = i
                }
            }else{
                indv = a.date
            }
        }
    })

    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="container  pt-5 pt-lg-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <div style={{position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
                                    <SectionMainHeading color={'color-normal'} text={fixLength(blogs[ind].title, 150)} />
                                    <br/>

                                    <ReadMoreBtn type={'link'} url={new URL(blogs[ind].link).pathname} text={'VIEW MORE'}/>
                                </div>
                            </div>
                            <div className="col-lg-6">

                                <img src={images.homeBanner}
                                     className="card-img"
                                     alt="yog banner"/>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
